import React, { createContext, useContext, useState } from "react";

type ThemeContextType = {
  language: string;
  setEnglish: () => void;
  setItaliano: () => void;
};

interface ProviderProps {
  children: React.ReactNode;
}

const LanguageContext = createContext<ThemeContextType>(null!);

export function LanguageProvider({ children }: ProviderProps) {
  const [language, setLanguage] = useState("english");

  const setEnglish = () => {
    setLanguage("english");
  };
  const setItaliano = () => {
    setLanguage("italiano");
  };

  const value = {
    language,
    setEnglish,
    setItaliano,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LanguageContext;
